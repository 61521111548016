import React from "react";
import "../../styles/global.css";
import styled from "@emotion/styled";
// components
import { Seo } from "../seo/seo";
import { Header } from "../header/header";
import { Footer } from "../footer/footer";
import { ProgressWrap } from "../wrapper/progressWrap";
import { FlowWrap } from "../wrapper/flowWrap";
import { BtnWrap } from "../wrapper/btnWrap";
// utils
import { Colors } from "../utils/colors";

export const FormLayout = (props) => {
  return (
    <>
      <Seo />
      <Header />
      <main>
        <SSection>
          <FlowWrap
            flow01={props.flow01}
            flow02={props.flow02}
            flow03={props.flow03}
          />
          <ProgressWrap
            progress01={props.progress01}
            progress02={props.progress02}
            progress03={props.progress03}
            progress04={props.progress04}
            progress05={props.progress05}
          />
          {props.children}
          <BtnWrap
            visibleBack={props.visibleBack}
            visibleNext={props.visibleNext}
            hrefBack={props.hrefBack}
            hrefNext={props.hrefNext}
            textBack={props.textBack}
            textNext={props.textNext}
          />
        </SSection>
      </main>
      <Footer />
    </>
  );
};

const SSection = styled.section`
  width: calc(100% - 4rem);
  min-height: calc(100% - 2rem);
  margin: auto;
  padding: 2rem;
  background-color: ${Colors.light};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .formwrap {
    min-height: calc(100% - 64px - 64px - 3rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: auto;
  }

  @media screen and (max-width: 599px) {
    width: calc(100% - 1rem);
    padding: 1rem;
  }
`;
