import React from "react";
import styled from "@emotion/styled";
// components
import { FormLayout } from "../components/layout/formLayout";
// utils
import { Colors } from "../components/utils/colors";

const Form02Page = () => {
  return (
    <FormLayout
      flow01="true"
      flow02="false"
      flow03="false"
      progress01="true"
      progress02="false"
      progress03="false"
      progress04="false"
      progress05="false"
      visibleBack="true"
      visibleNext="true"
      hrefBack="/form01/"
      hrefNext="/form03/"
      textBack="前の質問に戻る"
      textNext="次の質問に進む"
    >
      <SForm className="formwrap">
        <h3>お名前を入力してください</h3>
        <label>
          <span>*お名前</span>
          <input />
        </label>
      </SForm>
    </FormLayout>
  );
};

export default Form02Page;

const SForm = styled.div`
  h3 {
    margin-top: 4rem;
    color: ${Colors.dark};
    opacity: 0.7;
  }
  label {
    margin-top: 3rem;
    display: inline-flex;
    flex-direction: column;
    span {
      font-size: 0.8rem;
      margin-bottom: 0.25rem;
      font-weight: 500;
      opacity: 0.7;
    }
    input {
      width: 20rem;
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
      border-radius: 8px;
      border: 1px solid ${Colors.white};
      background-color: ${Colors.white};
      transition: all 0.2s;
      &:focus {
        outline: none;
        border-color: ${Colors.primary};
      }
    }
  }
`;
