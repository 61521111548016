import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
// utils
import { Colors } from "../utils/colors";

export const BtnWrap = (props) => {
  return (
    <SWrap>
      <div>
        <Link to={props.hrefBack} data-visible={props.visibleBack}>
          {props.textBack}
        </Link>
      </div>
      <div>
        <Link to={props.hrefNext} data-visible={props.visibleNext}>
          {props.textNext}
        </Link>
      </div>
    </SWrap>
  );
};

const SWrap = styled.div`
  margin-top: 2rem auto;
  height: 3rem;
  display: flex;
  justify-content: center;
  div {
    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      font-size: 0.9rem;
      font-weight: 500;
      text-decoration: none;
      padding: 1rem 2rem;
      min-width: 320px;
      border-radius: 8px;
      margin: 0 1rem;
      transition: 0.2s;
      &[data-visible="true"] {
      }
      &[data-visible="false"] {
        pointer-events: none;
      }
    }
    &:first-of-type {
      a {
        border: 1px solid ${Colors.gray};
        background-color: ${Colors.gray};
        color: ${Colors.dark};

        @media screen and (min-width: 600px) {
          &:hover {
            border-color: ${Colors.dark};
            background-color: ${Colors.light};
          }
        }
      }
    }
    &:last-of-type {
      a {
        border: 1px solid ${Colors.primary};
        background-color: ${Colors.primary};
        color: ${Colors.light};

        @media screen and (min-width: 600px) {
          &:hover {
            background-color: ${Colors.light};
            color: ${Colors.primary};
          }
        }
      }
    }
  }
  @media screen and (max-width: 599px) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    div {
      a {
        margin: 0rem;
        width: 100%;
        margin-top: 1rem;
        &:active {
          background-color: ${Colors.light};
          color: ${Colors.primary};
        }
      }
    }
  }
`;
